import { createAction, props } from '@ngrx/store';
import { AssignCarrier } from '../../interfaces/assign-carrier.interface';

export enum AssignCarrierActionsTypes {
    getAssignCarrier = '[AssignCarrier] Get AssignCarrier Shipping',
    successGetShipping='[AssignCarrier] Success Get AssignCarrier Shipping',
    errorGetShipping='[AssignCarrier] Error Get AssignCarrier Shipping' ,

    createAssignCarrier = '[AssignCarrier] Create AssignCarrier',
    successCreateAssignCarrier = '[AssignCarrier] Success Create AssignCarrier',
    errorCreateAssignCarrier = '[AssignCarrier] Error Create AssignCarrier',

    updateAssignCarrier = '[AssignCarrier] Update AssignCarrier',
    successUpdateAssignCarrier = '[AssignCarrier] Success Update AssignCarrier',
    errorUpdateAssignCarrier = '[AssignCarrier] Error Get AssignCarrier'
}

export const getAssignCarrier = createAction(AssignCarrierActionsTypes.getAssignCarrier,props<{filters:string}>());
export const successGetAssignCarrier= createAction(AssignCarrierActionsTypes.successGetShipping,props<{ assigncarrier: Array<AssignCarrier> }>());
export const errorGetAssignCarrier = createAction(AssignCarrierActionsTypes.errorGetShipping, props<{ payload: any }>());

export const createAssignCarrier = createAction(AssignCarrierActionsTypes.createAssignCarrier, props<{ assigncarrier: AssignCarrier }>());
export const successCreateAssignCarrier = createAction(AssignCarrierActionsTypes.successCreateAssignCarrier, props<{ assigncarrier: AssignCarrier }>());
export const errorCreateAssignCarrier = createAction(AssignCarrierActionsTypes.errorCreateAssignCarrier, props<{ payload: any }>());

export const updateAssignCarrier = createAction(AssignCarrierActionsTypes.updateAssignCarrier, props<{ assigncarrier: AssignCarrier, id: string }>());
export const successUpdateAssignCarrier = createAction(AssignCarrierActionsTypes.successUpdateAssignCarrier, props<{ assigncarrier: AssignCarrier }>());
export const errorUpdateAssignCarrier = createAction(AssignCarrierActionsTypes.errorUpdateAssignCarrier, props<{ payload: any }>());